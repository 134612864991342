<template>
  <div class="actions">
    <a
      href="javascript:void(0)"
      class="btn btn-primary btn-icon"
      data-toggle="modal"
      data-target="#update-espece"
      @click.prevent="setSelected"
    > 
      <i class="icofont icofont-pencil" />
    </a>
    <a
      href="javascript:void(0)"
      class="btn btn-danger btn-sm"
      @click.prevent="deletingEspece"
    > 
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { DELETE_ROBE } from '../../../graphql/identification';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return  {

        }
    },
    methods: {
        ...mapMutations({
            setSelectedEspece: 'SET_SELECTED_OBJECT'
        }),
        setSelected(){
            this.setSelectedEspece(this.object)
        },
        deletingEspece(){
            this.$apollo.mutate({
                mutation: DELETE_ROBE,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    if(data) console.log(`Robe ${this.object.libelle} at ${this.object.uid} deleted successfully`)
                    else console.log(`Error in deletting robe ${this.object.libelle}`)
                }
              }).then(() => {
                
              })

        }
    },
    computed: {
        ...mapGetters({

        })
    }
}
</script>

<style>

</style>